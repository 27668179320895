<template>
	<div id="schooling">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教学计划</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item">
						<el-button size="small" @click="openDialog(1)">添加计划</el-button>
						<el-button size="small" type="primary" @click="exportsTeachingPlan">导出</el-button>
					</div>
				</div>
				<el-table :data="list" height="72.9vh" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="year_name" label="学年" align="center" width="100"></el-table-column>
					<el-table-column prop="semester_name" label="学期" align="center" width="100"></el-table-column>
					<el-table-column prop="teacher_name" label="教师姓名" align="center" width="100"></el-table-column>
					<el-table-column prop="specialty_name" label="专业" align="center" width="200"></el-table-column>
					<el-table-column prop="course_name" label="课程" align="center"></el-table-column>
					<el-table-column label="操作" width="150" align="center">
						<template slot-scope="scope">
							<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="del(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加计划 -->
			<el-dialog :title="showTitle" :visible.sync="addSubject" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学年
						</div>
						<el-select v-model="yearId" placeholder="请选择学年" style="width: 75%" @change="yearChange">
							<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="padding-top: 10px;">
							<span>*</span>
							学期
						</div>
						<el-select v-model="semester_id" placeholder="请选择学期" style="width: 75%">
							<el-option v-for="item in xueqiList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							专业
						</div>
						<el-select v-model="specialty_id" placeholder="请选择专业" style="width: 75%;">
							<el-option v-for="item in ZyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="margin-top: 10px;">
							<span>*</span>
							课程
						</div>
						<el-select v-model="subjects_arr" multiple placeholder="请选择课程" style="width: 75%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="margin-top: 10px;">
							<span>*</span>
							教学计划
						</div>
						<el-input style="width: 75%;" type="textarea" :rows="5" placeholder="请输入内容" v-model="content"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			list: [],
			addSubject: false,
			loading: false,
			editid: '',
			page: 1,
			limit: 11,
			total: 0,
			showTitle: '添加计划',
			showType: 1,
			subjects_arr: [],
			subjectList: [],
			yearId: '',
			yearList: [],
			addVisible: false,
			xueqiList: [],
			semester_id: '',
			ZyList: [],
			content: '',
			specialty_id: ''
		};
	},
	mounted() {
		this.getInfo();
		this.getSubjectList();
		this.getYearList();
		this.getZyList();
	},
	methods: {
		// 专业列表
		getZyList() {
			this.$api.setting.getZyList({}).then(res => {
				this.ZyList = res.data.data.rows;
			});
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
				}
			});
		},
		getXueqiList() {
			this.$api.setting
				.getXueQiList({
					year_id: this.yearId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.xueqiList = res.data.data;
					}
				});
		},
		// 学科列表
		getSubjectList() {
			this.$api.setting.subjectList({}).then(res => {
				if (res.data.code == 1) {
					this.subjectList = res.data.data;
				}
			});
		},
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.showType = type;
			if (type == 1) {
				this.showTitle = '添加计划';
			} else if (type == 2) {
				this.showTitle = '编辑计划';
				this.editid = item.id;
				this.yearId = item.year_id;
				this.getXueqiList();
				let arr = [];
				let datas = item.course_id.split(',');
				for (let i in datas) {
					arr.push(Number(datas[i]));
				}
				this.subjects_arr = arr;
				this.specialty_id = item.specialty_id;
				this.semester_id = item.semester_id;
				this.content = item.content;
			}
			this.addSubject = true;
		},
		init() {
			this.yearId = '';
			this.subjects_arr = [];
			this.semester_id = '';
			this.specialty_id = '';
			this.content = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		yearChange() {
			if (this.yearId) {
				this.getXueqiList();
			}
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		// 教学计划列表
		getInfo() {
			this.$api.setting
				.teachingPlanList({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.list = res.data.data.rows;
						this.total = res.data.data.total;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 添加编辑计划
		submit() {
			if (this.showType == 1) {
				this.addStaff();
			} else {
				this.edit();
			}
		},
		addStaff() {
			this.$api.setting
				.addTeachingPlan({
					year_id: this.yearId,
					semester_id: this.semester_id,
					specialty_id: this.specialty_id,
					course_id: this.subjects_arr.toString(),
					content: this.content
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		edit() {
			this.$api.setting
				.editTeachingPlan({
					year_id: this.yearId,
					semester_id: this.semester_id,
					specialty_id: this.specialty_id,
					course_id: this.subjects_arr.toString(),
					id: this.editid,
					content: this.content
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		del(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delTeachingPlan({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getInfo();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		exportsTeachingPlan(){
			this.$api.setting.exportsTeachingPlan({}).then(res=>{
				if(res.data.code==1){
					this.$message.success('导出成功');
					location.href = res.data.data.url;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#schooling {
	.content {
		margin-top: 15px;
		background-color: #ffffff;
		height: 86vh;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
